// Bootstrap
$primary: #004785;
$secondary: #63666a;
$card-border-radius: 0;

// Kendo
$kendo-colors: (
  primary: #0072c6,
  primary-hover: #006dbe,
  primary-active: #0072c6,
);

// Kendo Dialog
$kendo-dialog-titlebar-bg: #0072c6;
$kendo-dialog-titlebar-text: #ffffff;

// Others
$light-border: rgba(0, 0, 0, 0.125);
$lineColors: (#e5d674, #29304a, #ff6d5d, #424242);
$formFieldHeight: 28px;
