.k-autocomplete,
.k-combobox,
.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-dropdown,
.k-multiselect,
.k-numerictextbox,
.k-maskedtextbox,
.k-timepicker,
.k-dropdowntree,
.k-textbox,
.k-input.k-textbox,
.k-input,
.k-textarea {
  width: 100%;
}

.readonly {
  &.readonly:not(.k-dateinput) {
    position: relative;

    &::after {
      content: '\f023';
      display: flex;
      position: absolute;
      top: 4px;
      right: 6px;
      font-family: 'font-awesome';
      font-size: 12px;
      color: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }

    &.k-numerictextbox {
      input {
        padding-right: 20px;
      }

      &[ng-reflect-decimals='0'] input {
        padding-right: inherit;
      }
    }

    > .k-multiselect-wrap {
      background-color: #ddd;
    }

    button.k-input-button {
      display: none;
    }
  }

  input:read-only,
  textarea:read-only {
    background-color: #ddd;
    padding-right: 17px;

    &::placeholder {
      visibility: hidden;
    }
  }
}

.tall-textarea .k-textarea {
  height: 11em;
}

.k-window {
  max-height: 100%;
}

.k-multiselect-wrap li .k-select {
  display: none !important;
  margin-left: 0 !important;
}

.k-multiselect-wrap li:hover .k-select {
  display: flex !important;
  margin-left: 8px !important;
}

div.layout-body {
  .form-table-row input:focus:not(:read-only),
  input.k-input:focus:not(:read-only),
  input.k-textbox:focus:not(:read-only) {
    background-color: #ffd;
    background-image: none;
  }

  .k-widget:not(.readonly) > .k-multiselect-wrap:focus-within:not(.readonly) {
    background-color: #ffd;
    background-image: none;
  }

  button.k-button:focus {
    outline-width: 4px;
    outline-style: auto;
    outline-color: rgb(0, 103, 244);
  }

  .k-dateinput-wrap input.k-input:focus {
    color: inherit;
    font-weight: inherit;
  }

  input:focus::placeholder,
  input.k-input:focus::placeholder,
  input.k-textbox:focus::placeholder {
    opacity: 1;
  }
}

input.k-input::selection,
input.k-textbox::selection,
textarea.k-textarea::selection,
*.k-input::selection {
  background: $primary;
  /* WebKit/Blink Browsers */
  color: white;
}

input.k-input::-moz-selection,
input.k-textbox::-moz-selection,
textarea.k-textarea::-moz-selection,
*.k-input::-moz-selection {
  background: $primary;
  /* Gecko Browsers */
  color: white;
}

.k-i-loading.large-spinner {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 110px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 64px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #ff6757;
  z-index: 10005;
}

.background .k-i-loading.large-spinner {
  z-index: 10000;
}

*.readonly .k-i-close {
  display: none;
}

.k-widget,
.k-item,
.k-textbox,
.k-button {
  font-size: 0.875rem;
}

@media only screen and (max-width: 767px) {
  .k-widget,
  .k-item,
  .k-textbox,
  .k-button {
    font-size: 16px;
  }
  .k-window-title {
    font-size: 14px;
  }
}

.k-dialog-content {
  .widescreen form > .card {
    margin-left: -16px;
    margin-right: -16px;

    > .card-header {
      display: none !important;
    }

    > .card-body {
      div:last-child:not(.always-show) {
        button.btn {
          display: none !important;
        }
      }
    }
  }

  .entity-footer {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -16px;
  }
}

.k-checkbox-label::before {
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.24);
}

//TEMP, until we migrate away from the static kendo css file
.k-item {
  .k-checkbox-label {
    display: none;
  }
}

.k-autocomplete,
.k-flatcolorpicker .k-color-value,
.k-combobox .k-dropdown-wrap,
.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap,
.k-dateinput .k-dateinput-wrap,
.k-textbox,
.k-textarea,
.k-input.k-textbox,
.k-multiselect-wrap,
.k-numerictextbox .k-numeric-wrap,
.k-multiselect .k-multiselect-wrap,
.k-form-field .k-checkbox,
.k-input-solid {
  border-color: rgba(0, 0, 0, 0.2);
}

.k-widget.k-numerictextbox {
  &[ng-reflect-decimals='0'] {
    .k-numeric-wrap {
      > input {
        text-align: left;
      }

      ::placeholder {
        text-align: left;
      }
    }
  }

  .k-numeric-wrap {
    > input {
      text-align: right;
    }

    ::placeholder {
      text-align: left;
    }
  }
}

.k-notification-group {
  //show in front of kendo dialogs
  z-index: 10002;
}

//force the dropdown sizes to not stretch
.k-clear-value {
  height: 26px;
}

.form-field-height {
  line-height: 28px;
}

.kendo-container {
  flex-wrap: wrap;
}

.kendo-counter {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.kendo-badge {
  border-radius: 0.5px;
}

.k-multiselect {
  height: auto;
}

.k-multiselect .k-clear-value {
  visibility: hidden;
  position: relative;
  top: 0;
  right: 0;
  z-index: 1;
}

.k-multiselect:hover .k-clear-value {
  visibility: visible;
}

.k-editor {
  max-width: 100%;
  min-width: 100%;
  min-height: 200px;
}

.k-editor .k-editor-content {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.k-editor .k-dialog {
  max-width: 98%;
  min-width: 50%;
}

.k-editor .k-dialog .k-edit-form-container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

.k-editor .k-dialog .k-edit-form-container .k-edit-field,
.k-editor .k-dialog .k-edit-form-container .k-edit-label {
  width: 100%;
  text-align: start;
  margin-bottom: 0;
}

.k-editor .k-dialog .k-edit-form-container .k-edit-field .k-checkbox {
  border-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 200px) and (max-width: 480px) {
  .k-editor {
    min-height: 500px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .k-editor {
    min-height: 300px;
  }
}

.k-chip {
  height: auto;
  .k-chip-content {
    .k-chip-label {
      text-wrap: wrap;
    }
    .k-chip-actions {
      display: initial;
    }
  }
}

.k-button-solid-danger {
  color: white;
  border-color: #dc3545;
  background-color: #dc3545;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));

  &:hover {
    border-color: #b02a37;
    background-color: #bb2d3b;
  }
}
