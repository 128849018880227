*[class^='t-col-'],
*[class*='t-col-'],
*.t-col {
  padding-left: 5px;
  padding-right: 5px;
}

*[class^='t-col-'],
*[class*='t-col-'],
*.t-col {
  position: relative;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

@each $key, $size in $grid-breakpoints {
  .t-col-#{$key} {
    @media (min-width: $size) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
  }

  @for $i from 1 through 100 {
    @media (min-width: $size) {
      .t-col-#{$key}-#{$i} {
        width: calc($i/100);
      }
    }
  }
}

.t-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
