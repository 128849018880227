@use '../node_modules/ag-grid-community/styles' as ag;

roco-frame {
  .ag-header-cell-text {
    white-space: normal;
    word-wrap: none;
  }
}

.ag-theme-balham {
  .ag-row-footer {
    border-top: 1px solid rgba(189, 195, 199, 0.58);

    .ag-cell-value {
      font-weight: bold;
    }
  }

  .ag-row-group {
    > .ag-cell-value {
      font-weight: bold;
    }
  }

  .ag-row.highlight-row-changes {
    background-color: rgba(255, 255, 0, 0.3);
  }

  .ag-row {
    .ag-cell {
      .ng-invalid.ng-dirty {
        input.k-input {
          background-color: rgba(255, 0, 0, 0.25);
          font-weight: bold;
          color: black;
        }
      }
    }
  }
}

.ag-theme-balham .ag-ltr *[class*='ag-row-footer'] .ag-row-group-leaf-indent {
  margin-left: 0;
}

.ag-cell-wrapper.ag-row-group-leaf-indent > .ag-group-value {
  width: 100%;
}

.ag-cell {
  .k-autocomplete,
  .k-combobox,
  .k-dateinput,
  .k-datepicker,
  .k-datetimepicker,
  .k-textbox,
  .k-dropdown,
  .k-multiselect,
  .k-numerictextbox,
  .k-maskedtextbox,
  .k-timepicker,
  .k-dropdowntree {
    vertical-align: top;
  }
}

//Not part of actual grid.  Refers to filters / controls in a flex div at the top of the grid.
.grid-header {
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px;

  &__divider {
    margin-right: auto;
  }
}

.mobile {
  .ag-theme-balham:not(.ignore-mobile-height) {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-root-wrapper div.ag-paging-panel {
    .ag-paging-row-summary-panel {
      margin: 0;
    }
  }
  .grid-header {
    justify-content: flex-start;
    &__divider {
      flex: 1 100%;
    }
  }
}

@include ag.grid-styles(
  (
    // ^^^ Include ag.grid-styles which it is no longer nested  in .ag-theme-{name} {}
    theme: balham,
    // ^^^ Add the theme name to the mixin
    odd-row-background-color: rgba(#000, 0.05),
    row-hover-color: rgba(#000, 0.1)
  )
);

.cursor-pointer {
  cursor: pointer;
}

.valid-cell {
  background-color: rgba(0, 255, 0, 0.1);
}

.invalid-cell {
  background-color: rgba(255, 0, 0, 0.1);
}

.warning-cell {
  background-color: rgba(255, 255, 0, 0.2);
}

.readonly-cell {
  opacity: 0.8;
  background-size: 5px 5px;
  background-image: repeating-linear-gradient(45deg, #ddd 0, #ddd 1px, transparent 0, transparent 50%);
}

.locked-cell {
  &::after {
    content: '\f023';
    display: flex;
    position: absolute;
    top: 0px;
    right: 1px;
    font-family: 'font-awesome';
    font-size: 9px;
    color: rgba(0, 0, 0, 0.3);
  }
}

.ag-grid-container {
  width: 100%;
  height: 100%;

  &--h200 {
    height: 200px;
  }

  &--h250 {
    height: 250px;
  }

  &--h300 {
    height: 300px;
  }

  &--h350 {
    height: 350px;
  }

  &--h400 {
    height: 400px;
  }

  &--h435 {
    height: 435px;
  }

  &--h500 {
    height: 500px;
  }
}

.ag-menu {
  z-index: 200001; // always show context menus above other content, e.g. dialog
}

.ag-popup-child {
  z-index: 200002; // always show context popup menus above other content
}

.ag-status-bar {
  overflow-x: auto;
}
