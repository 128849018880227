@import './styles-variables';
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-balham.css';
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import './ag-grid-styles.scss';
@import '../node_modules/@progress/kendo-theme-default/dist/all.scss';
@import '../node_modules/@progress/kendo-theme-default/scss/variables';
@import './kendo-styles.scss';
@import './assets/fontawesome.scss';
@import './t-grid.scss';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px,
  xxl: 1760px,
);

@import '../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../node_modules/bootstrap/scss/bootstrap-grid';
@import '../node_modules/bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Inconsolata';
  src:
    local('Inconsolata'),
    url(./assets/inconsolata-semiexpanded-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'font-awesome';
  src: url(assets/fa-solid-900.woff2);
}

@font-face {
  font-family: 'font-awesome-brands';
  src: url(assets/fa-brands-400.woff2);
}

@font-face {
  font-family: 'font-awesome-outline';
  src: url(assets/fa-regular-400.woff2);
}

.fas {
  font-family: 'font-awesome' !important;
  font-weight: 900;
  font-style: normal;
}

.fab {
  font-family: 'font-awesome-brands' !important;
  font-weight: 400;
  font-style: normal;
}

.far {
  font-family: 'font-awesome-outline' !important;
  font-weight: 400;
  font-style: normal;
}

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

html,
body,
roco-frame,
roco-frame > .frame-container,
roco-root {
  height: 100%;
  padding: 0;
  margin: 0;
}

roco-frame > .frame-container {
  background: #dddddd;
}

roco-frame {
  display: block;
  overflow: hidden;
}

roco-root {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.show-percentage {
  display: flex;

  &::after {
    line-height: 28px;
    content: '%';
    display: flex;
    margin-left: 2px;
    line-height: 30px;
  }
}

.form-field-height {
  line-height: $formFieldHeight;
}

.unit-label {
  line-height: 28px;
  padding-left: 0.3rem;
  font-size: small;
}

.unit-label.pre {
  padding-left: 0;
  padding-right: 0.3rem;
}

// Override global search combo box icon - has to be done here
.global-search .k-button-icon.k-icon.k-i-caret-alt-down:before {
  content: '\E13E';
}

.global-search button.k-input-button.k-button {
  height: auto;
}

.error-label {
  font-size: 12px;
  color: red;
}

.warning-label {
  font-size: 12px;
  font-weight: 700;
  color: #f59642;
}

.col-form-label {
  padding-top: 0px;
  padding-bottom: 0px;
}

.form-group {
  margin-bottom: 0.5rem;
}

/**
    Formerly in .widescreen
**/
*[class^='col-'],
*[class*='col-'],
*.col {
  padding-left: 5px;
  padding-right: 5px;
}

.row {
  margin-left: -5px;
  margin-right: -5px;
}

.card-body {
  padding: 0.75rem;
}

form.container {
  max-width: none;

  label,
  span,
  input,
  button {
    font-size: 0.8rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    span {
      font-size: inherit;
    }
  }

  span.k-remove-icon {
    font-size: 14px;

    > * {
      font-size: inherit;
    }
  }
}

/** end widescreen **/

//quill css tweak
.ql-container.ql-snow {
  min-height: 90px;
}

.mobile {
  .card-body {
    padding: 0.75rem;
  }

  .desktop-h-100 {
    height: auto;
  }
}

.desktop-h-100 {
  height: 100%;
}

@media (min-width: 992px) {
  .justify-content-lg-end.col-form-label {
    text-align: end;
  }
}

.drag-hover {
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
  }
}

*.ng-invalid.ng-touched {
  > .k-widget > .k-dateinput-wrap,
  > .k-widget > .k-dropdown-wrap,
  > .k-widget > .k-picker-wrap,
  > .k-widget > .k-multiselect-wrap,
  > .k-widget > .k-numeric-wrap,
  > .k-textbox {
    color: #f44336;
    border-color: rgba(244, 67, 54, 0.5);
  }
}

.form-divider-vertical-line {
  display: flex;
  width: 1px;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  margin: 0 0.5rem;
}

.form-divider-line {
  display: flex;
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin: 0.5rem 0;
}

.form-divider-line-dotted {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.125);
  display: flex;
  height: 1px;
  margin: 0.2rem -0.5rem;
}

.text-align-right {
  text-align: right;
}

.numerictextbox-wrapper {
  &--amount.k-numerictextbox .k-input-inner {
    text-align: right;
  }
  &--price.k-numerictextbox .k-input-inner {
    text-align: right;
  }
  &--quantity.k-numerictextbox .k-input-inner {
    text-align: right;
  }
  &--percentage.k-numerictextbox .k-input-inner {
    text-align: right;
  }
}

a[entitylink]:not([href]):not([tabindex]) {
  color: #004785;
  cursor: pointer;

  &:hover {
    color: black;
  }

  > i {
    color: black;
  }
}

.c-red {
  color: red;
}

.c-green {
  color: green;
}

.c-warning {
  color: #f59642;
}

.card .card-header h4 {
  margin-bottom: 0;
}

datepicker-editor .k-input {
  height: 24px;
}

.grid-container {
  min-width: 100%;
  height: 500px;
  flex-direction: column;

  &--h100 {
    height: 100%;
  }

  &__grid {
    width: 100%;
    flex-grow: 1;
  }
}

.container-button {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 10px;
  &__button {
    height: 40px;
    padding: 0 15px;
    min-width: 75px;
  }
}

.container-file-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.linksContainer {
  margin-left: 7px !important;
}

.keyLink {
  flex-grow: 0 !important;
  margin-right: 5px;
}

.menu-wrapper {
  overflow: auto;
  height: 100%;
  scrollbar-width: 0;
  font-size: 19px;
}

.flex-column-item,
.flex-filter-item {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  position: relative;

  > .drop-above,
  > .drop-below {
    z-index: 1;
    &.drag-hover {
      height: 35px;
      width: 100%;
      position: relative;
      top: none;
      left: none;
    }
    width: 100%;
    height: 5px;
    position: absolute;
  }

  > .drop-above {
    top: 0px;
    left: 0px;
  }

  > .drop-below {
    bottom: 0px;
    left: 0px;
  }
}

.vertical-input-dropdown-with-btn {
  width: 95%;
}

.horizontal-input-with-btn {
  margin-right: 0 !important;
}

.horizontal-btn {
  width: 12% !important;
  margin-left: 0 !important;
  @media (max-width: 1429px) {
    width: 15% !important;
  }
  @media (max-width: 820px) {
    width: 10% !important;
  }
  @media (max-width: 390px) {
    width: 7% !important;
  }
}

@media only screen and (max-width: 767px) {
  div.dual-list {
    flex-direction: column !important;
    align-items: center !important;

    div.listbox {
      width: 90% !important;

      &:nth-child(2) {
        margin-top: 30px !important;
      }

      div.record-picker {
        height: 350px;
      }
    }
  }
  div.dual-list div.listbox .point-right,
  div.dual-list div.listbox .point-left {
    position: relative;
    top: 500px;
  }
}

@media only screen and (min-width: 768px) {
  div.dual-list div.listbox .point-right,
  div.dual-list div.listbox .point-left {
    position: relative;
    top: 650px;
    bottom: 20px;
  }

  div.dual-list div.listbox .record-picker {
    height: 70%;
  }
}

.layouts-dropdown {
  width: 400px;
  @media only screen and (max-width: 767px) {
    width: 300px;
  }
}

@media only screen and (min-width: 897px) {
  .pf-formula-form {
    width: calc(50% - 8px);
  }
}
